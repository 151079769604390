export default class User {
  constructor(jwt, loginid, logonname, logondisplay, rt, cambiar_passkey) {
    this.jwt = jwt;
    this.loginid = loginid;
    this.logonname = logonname;
    this.logondisplay = logondisplay;
    this.rt = rt;
    this.cambiar_passkey = cambiar_passkey;
  }  
}
