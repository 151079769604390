import axios from 'axios';
import { CURRENT_APP_MODE, API_URL_AUTH, API_URL_AUTH_SIGNOUT, API_URL_AUTH_REGISTRO, API_URL_AUTH_ACTIVAR, API_URL_AUTH_RESET_PASSWORD, API_URL_AUTH_REFRESH, TOKEN_REFRESH_TIMEOUT } from './constants';
import { ERR_MSG_CONEXION } from './constants';

class AuthService {
  login(user) {
    return axios
      .post(API_URL_AUTH, {
        username: `${user.username}`,
        password: `${user.password}`
      })
      .then(response => {
        if (response.data.jwt) {   
          var currentDate = new Date();
          var futureDate = new Date(currentDate.getTime() + response.data.expire * 60000);

          response.data.expireby = futureDate;
          localStorage.setItem('user', JSON.stringify(response.data));          
        }

        return response.data;
      }).catch(
        err => {
          if(err.response){
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }          
        }  
      );
  }

  logout() {
    let user = JSON.parse(localStorage.getItem('user'));
    
    if(user) {
      axios.post(API_URL_AUTH_SIGNOUT, {token: `${user.rt}`}).then(() => {
        localStorage.removeItem('user');
      });
    }
  }

  registro(Login) {
    return this.registroCtx('', Login);    
  }

  registroCtx(AppCtx, Login) {
    let data = JSON.stringify(Login);
    
    return axios.post(API_URL_AUTH_REGISTRO, {AppCtx: `${AppCtx}`, AppMode: `${CURRENT_APP_MODE}`, Data: `${data}`}).then(response => {
      console.log(`${response.data}`);
      return response.data;
    }).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }          
      }  
    );
  }
  
  activar(id) {
    return axios.post(API_URL_AUTH_ACTIVAR, {id: `${id}`}).then(response => {
      return response.data;
    }).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }          
      }  
    );
  }

  resetPassword(email) {
    return axios.post(API_URL_AUTH_RESET_PASSWORD, {AppMode: `${CURRENT_APP_MODE}`, email: `${email}`}).then(response => {
      return response.data;
    }).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }          
      }  
    );
  }

  startMonitorToken(failAction)
  {
     let verificar = this.verificarToken.bind(this, failAction); 
     this.tmMonitorToken = setInterval(verificar, TOKEN_REFRESH_TIMEOUT);
  }

  stopMonitorToken()
  {    
    clearInterval(this.tmMonitorToken);
  }

  async verificarToken(failAction)
  {
    let user = JSON.parse(localStorage.getItem('user'));
    if(user)
    {
      let currentDate = new Date();
      let expireDate = Date.parse(user.expireby);
      
      if (currentDate > expireDate) { 
          try {
            await this.refreshToken(user);
          } catch (err)
          {          
            failAction();
          }
      }      
    } else {
      failAction();
    }
  }

  async refreshToken(user) {     
      await axios.get(API_URL_AUTH_REFRESH + `?token=${user.rt}`).then(response => {
        var currentDate = new Date();
        var futureDate = new Date(currentDate.getTime() + response.data.expire * 60000);
        user.jwt = response.data.jwt;
        user.rt = response.data.rt;
        user.expireby = futureDate;    
        
        localStorage.setItem('user', JSON.stringify(user));  
      }).catch(err => {
        throw err;
      });
  }
}

export default new AuthService();
