import AuthService from '../service/auth.service';
import UserService from '../service/user.service';


const user = JSON.parse(localStorage.getItem('user'));
const contextos = null;
const currentAppCtx = null;

const initialState = user
  ? { status: { loggedIn: true }, user, contextos, currentAppCtx }
  : { status: { loggedIn: false }, user: null, contextos: null, currentAppCtx: {menu:[]}};

export const auth = {
  namespaced: true,
  state: initialState,    
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    refreshToken({ commit }, user) {
      return AuthService.refreshToken(user).then(
        user => {
          commit('refreshSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('refreshFailure');
          return Promise.reject(error);
        }
      );
    },        
    contextos({ commit }) {
      return UserService.getContextos().then(ctxs =>
        {        
          commit('contextos', ctxs);
          return Promise.resolve(contextos);
        })        
    },
    contextosxtipo({ commit }, tipo) {
      return UserService.getContextosXTipo(tipo).then(ctxs =>
        {        
          commit('contextos', ctxs);
          return Promise.resolve(contextos);
        })        
    },    
    logout({ commit }) {
      AuthService.stopMonitorToken();
      AuthService.logout();
      commit('logout');
    },
    setAppCtx({commit}, AppCtx) {
      AppCtx.monedaBase = {};
      AppCtx.Sucursales = [];
      AppCtx.tiposMovimientoInv = [];
      AppCtx.tiposMovimientoCxc = [];
      AppCtx.menu = [];
      AppCtx.monedas = [];
      AppCtx.trxs = [];
      commit('SetAppCtxVigente', AppCtx)
    },
    setCatalogos({commit}, Data) {
      commit('SetCatalogosAppCtx', Data)
    },
    setMdsCatalogos({commit}, Data) {
      commit('SetMdsCatalogosAppCtx', Data)
    }, 
    setMdsContadoresTarifas({commit}, Data) {
      commit('SetMdsContadoresTarifasAppCtx', Data);
    },  
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  getters: {
    tieneAcceso: (state) => (acceso) => {
      return state.currentAppCtx.Permisos.find(p => p.id_acceso === acceso)
    },
    parametroValor: (state) => (parametro) => {
      let index = state.currentAppCtx.Parametros.findIndex(p => p.parametro === parametro);
      let valor = '';
      if(index>=0) {
        valor = state.currentAppCtx.Parametros[index].valor;        
      }
      return valor;
    },
    felHabilitado: (state) => {     
      let index = state.currentAppCtx.Parametros.findIndex(p => p.parametro == 'FEL_HABILITADO');
      let habilitado = false;
      if(index>=0){
        habilitado = (state.currentAppCtx.Parametros[index].valor == 'S');      
      }
      return habilitado;
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },    
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    contextos(state, contextos) {
      state.contextos = contextos;
    },
    SetAppCtxVigente(state, AppCtx)
    {
      state.currentAppCtx = AppCtx;
    },
    SetCatalogosAppCtx(state, data)
    {
      state.currentAppCtx.monedaBase = data.moneda_base[0];
      state.currentAppCtx.Pais = data.empresa_pais[0];
      state.currentAppCtx.Sucursales = data.sucursales;
      state.currentAppCtx.Bodegas = data.bodegas;
      state.currentAppCtx.Monedas = data.monedas;
      state.currentAppCtx.Empleados = data.empleados;
      state.currentAppCtx.tiposMovimientoInv = data.inv_tipo_movs;
      state.currentAppCtx.trxs = data.trxs;
      state.currentAppCtx.tiposMovimientoCxc = data.cxc_tipo_movs;	      
      state.currentAppCtx.formasPago = data.formas_pago.filter(fp => fp.activa == 'S');								
      state.currentAppCtx.Emisores = data.emisores;	 
      state.currentAppCtx.Paises = data.paises;	 
      state.currentAppCtx.direcciones_tipo = data.direcciones_tipo;	 
      state.currentAppCtx.Parametros = data.parametros;    
      state.currentAppCtx.menu = data.menu;       
      state.currentAppCtx.Permisos = data.permisos;
      state.currentAppCtx.MetodosEnvio = data.metodos_envio;
      state.currentAppCtx.CondicionesPago = data.condiciones_pago;   
      state.currentAppCtx.ctahabiente_tipos = data.ctahabiente_tipos;    
      state.currentAppCtx.ctahabiente_categorias = data.ctahabiente_categorias;       
      state.currentAppCtx.ctahabiente_regiones = data.ctahabiente_regiones;
      state.currentAppCtx.precio_listas = data.precio_listas;
      state.currentAppCtx.producto_listas = data.producto_listas;   
      state.currentAppCtx.plantillas_mov = data.plantillas_mov;           
    },    
    SetMdsCatalogosAppCtx(state, data)
    {
      state.currentAppCtx.complejo = data.complejo;
      state.currentAppCtx.entidades_tipo = data.entidades_tipo;
      state.currentAppCtx.inmuebles_clasificaciones = data.inmuebles_clasificaciones;
      state.currentAppCtx.reclamos_tipo = data.reclamos_tipo;
      state.currentAppCtx.reclamos_estado = data.reclamos_estado;      
      state.currentAppCtx.contadores_tipo = data.contadores_tipo;
      state.currentAppCtx.contadores_tarifas = null;
    }, 
    SetMdsContadoresTarifasAppCtx(state, Data) {
      state.currentAppCtx.contadores_tarifas = Data;
    },      
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};
