<template>
	<div class="layout-topbar">

			<button class="p-link layout-menu-button layout-topbar-icon" @click="onMenuButtonClick">
				<i class="pi pi-bars"></i>
			</button>		
			<router-link to="/" class="p-link layout-topbar-logo">	
				<!-- <img id="topbar-logo" src="assets/layout/images/logo-roma-white.svg" alt="roma-vue"/> -->
				<h3 style="color: white;margin: 0; position: absolute; top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%);">
				{{ this.getCurrentAptCtxName }}
				<p v-if="this.statusDisplay !=''" style="color: Red; background-color: pink; font-size: .50em">La cuenta se encuentra {{ this.statusDisplay }}</p>
				</h3>															
			</router-link>
			<ul class="topbar-menu">
				<li :class="['user-profile', {'active-topmenuitem fadeInDown': activeTopbarItem === 'profile'}]">
					<button class="p-link layout-topbar-icon" v-if="!this.inlineUser"
							@click="$emit('topbar-item-click',{originalEvent:$event,item:'profile'})">						
						<div class="layout-profile-userinfo">
							<span class="layout-profile-name">{{ this.getUserName }}</span>
							<span class="layout-profile-role">{{ this.getCurrentAptCtxTipo }}</span>							
						</div>
						<i class="topbar-icon pi pi-fw pi-ellipsis-v"></i>	
					</button>
					<transition name="layout-submenu-container">
						<ul class="fadeInDown" v-show="activeTopbarItem === 'profile'">
							<li role="menuitem">
								<button class="p-link" @click="cambiarcontrasena">
									<i class="pi pi-fw pi-user"></i>
									<span>Cambiar Contraseña</span>																						
								</button>
							</li>
							<!-- <li role="menuitem">
								<button class="p-link">
									<i class="pi pi-fw pi-cog"></i>
									<span>Settings</span>
								</button>
							</li> -->
							<li role="menuitem">
								<button class="p-link" @click="signOff">
									<i class="pi pi-fw pi-power-off"></i>
									<span>Cerrar sesión</span>
								</button>
							</li>
						</ul>
					</transition>
				</li>
			</ul>


		<Dialog v-model:visible="showStatusDialog" header="AVISO IMPORTANTE" modal :closable="false">	
			<template #header>
				<div  class="p-text-bold" style="font-size: 1.25em;">
				<p><i class="pi pi-exclamation-circle"/> AVISO IMPORTANTE</p>
				</div>
			</template>			
			<div class="p-m-2  p-d-flex p-m-1 p-jc-center">
				<label class="p-text-bold">Su cuenta se encuentra</label>				
			</div>	
			<div v-if="getCurrentAptCtxEstado=='V'" class="p-m-4 p-d-flex p-jc-center" style="color: Orange;font-size: 1.5em;">
				<label class="p-text-bold">VENCIDA</label>				
			</div>
			<div v-else="getCurrentAptCtxEstado=='S'" class="p-m-4 p-d-flex p-jc-center" style="color: Red;font-size: 1.5em;">
				<label class="p-text-bold">SUSPENDIDA</label>				
			</div>	
			<div v-if="getCurrentAptCtxEstado=='V'" class="p-m-2 p-d-flex p-jc-center">
				<label class="p-text">Para evitar la interrumpción de su servicio contacte a su proveedor!</label>				
			</div>	
			<div v-else="getCurrentAptCtxEstado=='S'" class="p-m-2 p-d-flex p-jc-center">
				<label class="p-text">Para restablecer el servicio contacte a su proveedor!</label>				
			</div>									
			<template #footer>
				<Button label="Aceptar" icon="pi pi-check" @click="showStatusDialog = false" autofocus />
			</template>	
		</Dialog>		
	</div>


</template>

<script>
	export default {
		emits: ['menubutton-click', 'topbar-menubutton-click', 'topbar-item-click', 'right-menubutton-click'],
		computed: {
			getUserName()
			{
				if(this.$store.state.auth.user) {
					return this.$store.state.auth.user.logondisplay;
				} else {
					return '';
				}
			},
			getCurrentAptCtxName() {
				if(this.$store.state.auth.currentAppCtx) {
					return this.$store.state.auth.currentAppCtx.contexto;
				} else {
					return '';
				}
			},
			getCurrentAptCtxTipo() {
				if(this.$store.state.auth.currentAppCtx) {
					return this.$store.state.auth.currentAppCtx.contexto_tipo;
				} else {
					return '';
				}
			},
			getCurrentAptCtxEstado() {
				if(this.$store.state.auth.currentAppCtx) {
					return this.$store.state.auth.currentAppCtx.id_contexto_estado;
				} else {
					return '';
				}
			},
		},
		mounted(){
			let status = this.getCurrentAptCtxEstado;

			if(status=='V') {
				this.statusDisplay = '[VENCIDA]'
			}

			if(status=='S') {
				this.statusDisplay = '[SUSPENDIDA]'
			}

			if ((status=='V') || (status=='S')) {
				this.showStatusDialog = true;
			}
		},
		data() {
			return {
				showStatusDialog: false,
				statusDisplay: '',
			}
		},		
		props: {
			topbarMenuActive: Boolean,
			activeTopbarItem: String,
			inlineUser: Boolean,
		},
		methods: {
			signOff() {
				this.$store.dispatch('auth/logout');
				this.$router.push('/login');
			},
			cambiarcontrasena()
			{
				this.$router.push('/cambiarcontrasena');
			},
			onMenuButtonClick(event) {
				this.$emit('menubutton-click', event);
			},
			onTopbarMenuButtonClick(event) {
				this.$emit('topbar-menubutton-click', event);
			},
			onRightMenuButtonClick(event) {
				this.$emit('right-menubutton-click', event);
			}
		}	
	}
</script>
