import axios from 'axios';
import { authHeader } from './auth-header';
import { API_URL_REGISTRO, API_URL_CATALOGO, API_URL_REGISTRO_LOGIN_X_EMAIL, API_URL_REGISTRO_ACTUALIZA_PASSKEY, API_URL_REGISTRO_LOGIN_X_USUARIO, API_URL_REGISTRO_GEN_ACTIVACION, CURRENT_APP_MODE, ERR_MSG_CONEXION } from './constants';

class UserService{
  getContextos()
  {
    return axios.post(API_URL_REGISTRO + '/contextos', {}, { headers: authHeader() }).then(response => {
      return response.data;
      }
    );
  }
 
  getContextosXTipo(tipo)
  {
    return axios.get(API_URL_REGISTRO + `/contextosxtipo?Tipo=${tipo}`, { headers: authHeader() }).then(response => {
      return response.data;
      }
    );
  }

  getLoginXEmail(email) {
    return axios.post(API_URL_REGISTRO_LOGIN_X_EMAIL, {email: `${email}`}, { headers: authHeader() }).then(response => {
      return response.data;
    }).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }          
      }  
    );
  }

  getLoginXUsuario(usuario) {
    return axios.post(API_URL_REGISTRO_LOGIN_X_USUARIO, {usuario: `${usuario}`}, { headers: authHeader() }).then(response => {
      return response.data;
    }).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }          
      }  
    );
  }

  actualizarContrasena(nuevaContrasena) {
    return axios.post(API_URL_REGISTRO_ACTUALIZA_PASSKEY, {nuevaContrasena: `${nuevaContrasena}`}, { headers: authHeader() }).then(response => {
      return response.data;
    }).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }          
      }  
    );    
  }

  eliminarUsuario(AppCtx, Login) {
    return axios.post(API_URL_REGISTRO + '/removerlogin', {AppCtx: `${AppCtx}`, Id: Login.id}, { headers: authHeader() }).then(response => {
      return response.data;
    }).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }          
      }  
    );
  } 

  generarActivacion(AppCtx, Login) {
    return axios.post(API_URL_REGISTRO_GEN_ACTIVACION, {AppCtx: `${AppCtx}`, AppMode: `${CURRENT_APP_MODE}`, Id: `${Login.id}`}, { headers: authHeader() }).then(response => {
      console.log(`${response.data}`);
      return response.data;
    }).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }          
      }  
    );
  }

  getContextosXLogin(user)
  {
    return axios.get(API_URL_REGISTRO + `/GetContextosXLogin?Id=${user.logonid}`, { headers: authHeader() }).then(response => {
      return response.data;
      }
    );
  }

  getLoginsXContextos(AppCtx)
  {
    return axios.get(API_URL_REGISTRO + `/loginsxcontexto?AppCtx=${AppCtx.codigo}`, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getGrupoAccesoXId(AppCtx, id)
  {
    return axios.post(API_URL_CATALOGO + '/getgrupoaccesoxid', {AppCtx: `${AppCtx.codigo}`, Id: `${id}`}, { headers: authHeader() }).then(response => {
      return response.data;
    }).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }          
      }  
    );
  }

  getAccesosXLogin(AppCtx, login)
  {
    return axios.post(API_URL_CATALOGO + '/getaccesosxlogin', {AppCtx: `${AppCtx.codigo}`, Login: `${login}`}, { headers: authHeader() }).then(response => {
      return response.data;
    }).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }          
      }  
    );
  }

  
  setAccesoLogin(AppCtx, login, Acceso)
  {
    let data = {
      AppCtx: `${AppCtx.codigo}`, 
      Login: `${login.login}`, 
      Acceso: `${Acceso.id_acceso}`, 
      Activo: Acceso.asignado};

    return axios.post(API_URL_CATALOGO + '/setaccesologin', data, { headers: authHeader() }).then(response => {
      return response.data;
    }).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }          
      }  
    );
  }

  getPublicContent() {
    return axios.get(API_URL_REGISTRO + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL_REGISTRO + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL_REGISTRO + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL_REGISTRO + 'admin', { headers: authHeader() });
  }
}

export default  new UserService();
