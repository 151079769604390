<template>
	<div class="layout-footer">
		<div>
			<img :src='logo' alt="roma-layout"/>
			<p></p>
		</div>
		<div class="footer-icons">
			<div v-if="appEnv==0" style="text-align:right; background-color: pink; color: red">DEVELOPMENT		
			</div>
		</div>
		<!-- <div class="footer-icons">
			<button class="p-link">
				<i class="pi pi-home"></i>
			</button>
			<button class="p-link">
				<i class="pi pi-globe"></i>
			</button>
			<button class="p-link">
				<i class="pi pi-envelope"></i>
			</button>
		</div> -->
	</div>
</template>

<script>
import { CURRENT_ENV, LOGO_LOGIN } from './service/constants';

	export default {
		name: "AppFooter",
		data()
		{
			return {
				appEnv: CURRENT_ENV,
				logo: `assets/layout/images/${LOGO_LOGIN}`,
			}
		}
	}
</script>

<style scoped>

</style>
