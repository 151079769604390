<template>
	<!-- <div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div> -->
	<form @submit.prevent="onSubmit">
	<div :class="backgroundStyle">
		<div class="center">
			<Card v-if="!registrado">
				<template #title>
					<h3 class="p-text-bold" style="text-align: center">Recuperación de Contraseña</h3>
					<h5>Ingresar dirección de correo electrónico registrada en su cuenta, para recibir la nueva contraseña</h5>
				</template>
				<template #content>
					<div class="p-field">
						<div class="p-inputgroup">
							<span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
							<InputText :disabled="loading" v-model="v$.form.email.$model" placeholder="email" :class="{'p-invalid': v$.form.email.$error}"/>
						</div>
						<div class="p-error" v-if="v$.form.email.email.$error">La dirección de email es inválida</div>
					</div>		
					<div class="p-fluid p-field p-text-center">															
						<Button class="p-fluid" :disabled="(v$.form.$errors.length > 0)" label="Recuperar" :icon="procesarIcon" @click="recuperar" iconPos="right"/>
						<Message v-for="msg of messages" :severity="msg.severity" :key="msg.content">{{msg.content}}</Message>
					</div>						
				</template>				
			</Card>
			<Card v-if="registrado">
				<template #header>					
					<div class="p-field p-text-center">
					<img src="/complete.jpg" style="height: 15rem; width: 15rem" />
					</div>
				</template>
				<template #title>
					<div class="p-field p-text-center">				
					¡Tu contraseña ha sido restablecida!
					</div>
				</template>
				<template #content>
					<div class="p-field">
						<label class="p-text-center">Si el email que proporcionaste está registrado, recibirás una nueva contraseña en los próximos minutos.</label>	
					</div>						
					<div class="p-field">
						<label class="p-text-center">Si no ves el correo con la nueva contraseña, por favor revisa en tu buzon de correos no deseados.</label>	
					</div>						
				</template>
				<template #footer>
					<div class="p-field p-text-center">
					<Button icon="pi pi-check" label="Continuar" @click="continuar"/>
					</div>
				</template>	
			</Card>			
		</div>
	</div>
	</form>
</template>

<script>
	// import Loading from 'vue-loading-overlay';
	// // Import stylesheet
	// import 'vue-loading-overlay/dist/vue-loading.css';
	import GrlService from '../service/grl.service';
	import MdsService from '../service/mds.service';
	import useVuelidate from  '@vuelidate/core';
	import { email, required } from '@vuelidate/validators';	
	import { ERR_MSG_CONEXION, APPNAME, BACKGROUND_STYLE } from '../service/constants';
	import AuthService from '../service/auth.service';

	export function validName(name) {
		let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
		
		if (validNamePattern.test(name)){
			return true;
		}
		
		return false;
	}

	export default {
		setup() { 
			return { v$: useVuelidate() };
		},
		components: {
			// Loading
		},
		validations () {
			return {
				form: {
					email: {required, email},	
				}
			}			
		},			
		data() {
			return {
				loading: false,
				registrado: false,
				appName: APPNAME,	
				messages: [],
				backgroundStyle: `exception-body notfound ${BACKGROUND_STYLE}`,
				form: {
					email: '',
				}								
			}
		},
		mounted() {
			this.v$.$reset();
		},		
		computed: {
			procesarIcon() {
				if(!this.loading) {
					return "pi pi-envelope";					
				} else {
					return "pi pi-spin pi-spinner";
				}
			}
		},
		methods: {
			recuperar(){				
				this.loading = true;
				this.messages = [];				
				AuthService.resetPassword(this.form.email).then(()=> {
					this.loading = false;
					this.registrado = true;
				}).catch((error)=>{
					this.loading = false;
					this.messages.push(
					{severity: 'error', content: `${error.error}`}						
					);
				});
			},

			continuar() {
				this.$router.push('/login');
			},

			goCtx(ctx)
			{
				this.$store.dispatch('auth/setAppCtx', ctx);
				
				GrlService.getCargarCatalogosBase(this.$store.state.auth.currentAppCtx).then(
					data => {
						this.$store.dispatch('auth/setCatalogos', data);			

						if(this.$store.state.auth.currentAppCtx.id_contexto_tipo == 2) { //Es Ctx Multidomus
							MdsService.getCatalogosBase(this.$store.state.auth.currentAppCtx).then(
								data => {
									this.$store.dispatch('auth/setMdsCatalogos', data);
									this.$router.push('/');
								}
							).catch(
								err => {
									if(err.response){
										return Promise.reject(err.response.data);          
									} else {
										return Promise.reject(ERR_MSG_CONEXION);
									}        
								}
							)	
						} else {
							this.$router.push('/');							
						}
					}
				).catch(
					err => {
						if(err.response){
							return Promise.reject(err.response.data);          
						} else {
							return Promise.reject(ERR_MSG_CONEXION);
						}        
					}
				);		
			}
		}
	}
</script>

<style scoped>
	.main {
		top: 100px;
		width: 500px;
		margin: 0px auto;
		padding: 50px auto;
		border: 1px solid;
	}

	.center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 10px;
	}

	.right {
	float: right;
	}	

	.mkt-bkg {
		background: url("/mkt-login-bg.jpg") no-repeat;	
		background-size: cover;
	}

	.mds-bkg{
		background: url("/mds-login-bg.jpg") no-repeat;
		background-size: cover;
	}	

	::v-deep(.p-password input) {
		width: 15rem
	}
</style>
