<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<form @submit.prevent="onSubmit">
	<div :class="backgroundStyle">
		<!-- <div class="exception-panel">
			<div class="exception-content">
				<img src="assets/layout/images/pages/icon-404.svg" alt="roma"/>
				<h1>Afiliaciones</h1>
				<Button label="Regresar al inicio de sesión" icon="pi pi-arrow-left" @click="logout" />
			</div>			
		</div> -->
		<div class="center">
			<Card v-if="!registrado">
				<template #title>
					<h3 class="p-text-bold" style="text-align: center">Cambio de Contraseña</h3>
					<h5>Por favor registre su nueva contraseña</h5>
				</template>
				<template #content>
					<div class="p-fluid">
						<div class="p-field">
							<div class="p-inputgroup">
							<span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
								<InputText type="password" v-model="v$.form.passKey.$model" placeholder="Contraseña" :class="{'p-invalid': v$.form.passKey.$error}"/>				
								<!-- <Password v-model="v$.form.passKey.$model" placeholder="Contraseña" :class="{'p-invalid': v$.form.passKey.$error}" toggleMask/> -->
							</div>
							<div v-if="v$.form.passKey.required.$error">
								<div class="p-error">Debe proporcionar una constraseña</div>	
							</div>											
							<div v-if="v$.form.passKey.min.$error">
								<label class="p-error">Debe proporcionar una constraseña</label>	
							</div>											

							<!-- <div v-for="(error, index) of v$.form.passKey.$errors" :key="index">
								<div class="p-error">{{ error.$message }}</div>
							</div>								 -->
						</div>		
						<div class="p-field">
							<div class="p-inputgroup">
							<span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
								<InputText type="password" v-model="v$.form.confirmacion.$model" placeholder="Confirmar contraseña" :class="{'p-invalid': v$.form.confirmacion.$error}"/>
							</div>
							<div v-if="v$.form.confirmacion.sameAsPasskey.$error">
								<div class="p-error">Las contraseña y la confirmación deben ser iguales</div>	
							</div>														
						</div>								
						<Button class="p-fluid" :disabled="(v$.form.$errors.length > 0)" label="Aceptar" icon="pi pi-check" @click="aceptar" />
						<Message v-for="msg of messages" :severity="msg.severity" :key="msg.content">{{msg.content}}</Message>
					</div>					
				</template>				
			</Card>
			<Card v-if="registrado">
				<template #header>
					<div class="p-field p-text-center">
						<img src="/complete.jpg" style="height: 15rem; width: 15rem" />
					</div>
				</template>
				<template #title>
					<div class="p-field p-text-center">		
					¡Tu contraseña ha sido actualizada con éxito!
					</div>					
				</template>
				<template #content>
					<div class="p-field p-text-center">		
					<p>Presiona el botón de continuar cuando estés listo.</p>
					</div>
				</template>
				<template #footer>
					<div class="p-field p-text-center">		
					<Button icon="pi pi-check" label="Continuar" @click="continuar"/>
					</div>
				</template>	
			</Card>			
		</div>
	</div>
	</form>
</template>

<script>
	import Loading from 'vue-loading-overlay';
	// Import stylesheet
	import 'vue-loading-overlay/dist/vue-loading.css';
	import useVuelidate from  '@vuelidate/core';
	import { required, minLength, sameAs } from '@vuelidate/validators';	
	import { APPNAME, BACKGROUND_STYLE } from '../service/constants';
	import UserService from '../service/user.service';

	export default {
		setup() { 
			return { v$: useVuelidate() };
		},
		components: {
			Loading
		},
		validations () {
			return {
				form: {
					passKey: {required, min: minLength(6)},
					confirmacion: {
						sameAsPasskey: sameAs(this.form.passKey),
					}		
				}
			}			
		},			
		data() {
			return {
				loading: false,
				registrado: false,
				appName: APPNAME,	
				messages: [],
				backgroundStyle: `exception-body notfound ${BACKGROUND_STYLE}`,
				form: {
					passKey: '',
					confirmacion: ''
				}								
			}
		},
		mounted() {
			this.v$.$reset();
		},		
		methods: {
			aceptar(){
				this.loading = true;
				this.messages = [];				
				UserService.actualizarContrasena(this.form.passKey).then(()=> {
					if(this.$store.state.auth.user != null) {
						this.$store.state.auth.user.cambiar_passkey = 'N';
					}

					this.loading = false;
					this.registrado = true;
				}).catch((error)=>{
					this.loading = false;
					this.messages.push(
					{severity: 'error', content: `${error.error}`}						
					);
				});
			},

			continuar() {
				this.$router.push('/');	
			},
		}
	}
</script>

<style scoped>
	.main {
		top: 100px;
		width: 500px;
		margin: 0px auto;
		padding: 50px auto;
		border: 1px solid;
	}

	.center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 10px;
	}

	.right {
	float: right;
	}	

	.mkt-bkg {
		background: url("/mkt-login-bg.jpg") no-repeat;	
		background-size: cover;
	}

	.mds-bkg{
		background: url("/mds-login-bg.jpg") no-repeat;
		background-size: cover;
	}	

	::v-deep(.p-password input) {
		width: 15rem
	}
</style>
