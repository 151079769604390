<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div :class="backgroundStyle">
		<!-- <div class="exception-panel">
			<div class="exception-content">
				<img src="assets/layout/images/pages/icon-404.svg" alt="roma"/>
				<h1>Afiliaciones</h1>
				<Button label="Regresar al inicio de sesión" icon="pi pi-arrow-left" @click="logout" />
			</div>			
		</div> -->
		<div class="center">
			<Card v-if="activo==1">
				<template #header>
					<center>
					<img src="/complete.jpg" style="height: 15rem; width: 15rem" />
					</center>
				</template>
				<template #title>
					<center>
					¡La cuenta ha sido activada con éxito!
					</center>
				</template>
				<template #content>
					<center>
					<p>Presiona el ó de continuar para ingresar a la aplicación</p></center>
				</template>
				<template #footer>
					<center>
					<Button icon="pi pi-check" label="Continuar" @click="continuar"/>
					</center>
				</template>	
			</Card>			
			<Card v-if="activo==0">
				<template #header>
					<center>
					<img src="/error.png" style="height: 15rem; width: 15rem" />
					</center>
				</template>
				<template #title>
					<center>
					¡Tu cuenta NO ha podido ser activada!
					</center>
				</template>
				<template #content>
					<center>
					<p>Es posible que el período de activación haya expirado, o que la cuenta ya se encuentre activa.</p></center>
				</template>
				<template #footer>
					<center>
					<Button icon="pi pi-check" label="Continuar" @click="continuar"/>
					</center>
				</template>	
			</Card>					
		</div>
	</div>
</template>

<script>
	import Loading from 'vue-loading-overlay';
	// Import stylesheet
	import 'vue-loading-overlay/dist/vue-loading.css';
	import {APPNAME, BACKGROUND_STYLE } from '../service/constants';
	import AuthService from '../service/auth.service';


	export default {
		components: {
			Loading
		},
		data() {
			return {
				loading: false,
				activo: -1,
				appName: APPNAME,	
				messages: [],
				backgroundStyle: `exception-body notfound ${BACKGROUND_STYLE}`							
			}
		},
		mounted() {
			this.activar();
		},		
		methods: {
			activar(){
				this.messages = [];
				var id = this.$route.query.id;		
				this.activo = -1;
				if(id) {
					this.loading = true;
					AuthService.activar(id).then((response)=> {
						console.log(response);
						this.loading = false;

						if(response.result) {
							this.activo = 1;
						} else {
							this.activo = 0;
						}
					}).catch((error)=>{
						this.loading = false;
						this.activo = 0;
						this.messages.push(
						{severity: 'error', content: `${error.error}`}						
						);
					});
				} else {
					this.activo = 0;				
				}
			},

			continuar() {
				this.$router.push('/');
			}
		}
	}
</script>

<style scoped>
	.main {
		top: 100px;
		width: 500px;
		margin: 0px auto;
		padding: 50px auto;
		border: 1px solid;
	}

	.center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 10px;
	}

	.right {
	float: right;
	}	

	.mkt-bkg {
		background: url("/mkt-login-bg.jpg") no-repeat;	
		background-size: cover;
	}

	.mds-bkg{
		background: url("/mds-login-bg.jpg") no-repeat;
		background-size: cover;
	}	

	::v-deep(.p-password input) {
		width: 15rem
	}
</style>
