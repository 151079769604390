//import { createStore } from 'vuex'
import { createStore } from 'vuex'
import { auth } from './auth.module';

// Create a new store instance.
const store = createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth
  }
})

export default store;