<template>
	<div :class="backgroundStyle">
		<!-- <div class="exception-panel">
			<div class="exception-content">
				<img src="assets/layout/images/pages/icon-404.svg" alt="roma"/>
				<h1>Afiliaciones</h1>
				<Button label="Regresar al inicio de sesión" icon="pi pi-arrow-left" @click="logout" />
			</div>			
		</div> -->
		<div class="center">
			<DataView :value="ctxs" sortField="codigo">
				<template #header>
					<div class="p-d-inline-flex">
					<Button icon="pi pi-angle-left" class="p-button-rounded p-button-secondary p-m-2" @click="logout"/>				
					<div class="p-mt-3 p-mb-3 p-mr-1">Seleccione la afiliación con la que desea trabajar</div>
					</div>
				</template>
				<template #list="slotProps">				
				<div style="padding: .5em; margin: 2px; width: 175px;" class="p-col-12 p-md-3">
					<Panel :header="slotProps.data.contexto_tipo" style="text-align: center;">
						<template #icons>						
							<i v-if="slotProps.data.id_contexto_estado=='A'" class="pi pi-circle-on green" style="color: DarkSeaGreen; font-size: .75em"/>
							<i v-if="slotProps.data.id_contexto_estado=='V'" class="pi pi-circle-on green" style="color: Orange; font-size: .75em"/>
							<i v-if="slotProps.data.id_contexto_estado=='S'" class="pi pi-circle-on green" style="color: Red; font-size: .75em"/>
						</template>
						<!-- <img :src="'demo/images/car/' + slotProps.data.brand + '.png'" :alt="slotProps.data.tipo"/> -->
						<div class="p-m-2">{{slotProps.data.contexto}}</div>			
						<div v-if="slotProps.data.id_contexto_estado!='S'"> 
							<Button label="Acceder" class="p-button-outlined"  @click="goCtx(slotProps.data)"/>
						</div>			
						<div v-else>
							<Button label="Suspendido" class="p-button-outlined"  disabled="disabled"/>
						</div>						
					</Panel>
				</div>
				</template>			
			</DataView>
		</div>
		<Dialog v-model:visible="loading" :modal="true" :closable="false" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '35vw'}">					            
			<div class="flex align-content-center flex-wrap card-container">
				<div class="p-d-flex p-ai-center p-jc-center p-mb-2">
					<ProgressSpinner style="width:50px;height:50px"/>
				</div>
				<div class="p-d-flex p-ai-center p-jc-center p-mb-2">
				<p>Por favor, espere un momento mientras se establece la configuración..</p>
				</div>
			</div>
		</Dialog>					
	</div>
</template>

<script>
	import GrlService from '../service/grl.service';
	import MdsService from '../service/mds.service';
	import { ERR_MSG_CONEXION, CTX_TIPO_CONFIG, BACKGROUND_STYLE } from '../service/constants';
	export default {
		data() {
			return {
				SelCtx: '',
				ctxs: null,
				loading: false,
				backgroundStyle: `exception-body notfound ${BACKGROUND_STYLE}`,
			}
		},
		mounted() {
			this.ctxs = this.$store.state.auth.contextos;
		},		
		methods: {
			logout(){
				this.$store.dispatch('auth/logout');
				this.$router.push({ path: '/login' });
			},
			refrescar()
			{
				this.$store.dispatch('auth/contextosxtipo', CTX_TIPO_CONFIG).then(
					this.ctxs = this.$store.state.auth.contextos
				)
			},
			async goCtx(ctx)
			{
				this.loading = true;

				try {
					await this.$store.dispatch('auth/setAppCtx', ctx);

					var data = await GrlService.getCargarCatalogosBase(this.$store.state.auth.currentAppCtx);
					await this.$store.dispatch('auth/setCatalogos', data); 

					if(this.$store.state.auth.currentAppCtx.id_contexto_tipo == 2) { //Es Ctx Multidomus
						var mdsData = MdsService.getCatalogosBase(this.$store.state.auth.currentAppCtx)
						await this.$store.dispatch('auth/setMdsCatalogos', mdsData);

						if(this.$store.state.auth.user.cambiar_passkey == 'S') {
							this.$router.push('/cambiarcontrasena');	
						} else {
							this.$router.push('/');
						}
					} else {
						if(this.$store.state.auth.user.cambiar_passkey == 'S') {
							this.$router.push('/cambiarcontrasena');	
						} else {
							this.$router.push('/');
						}
					}

					this.loading = false;
				} catch (err) {
					this.loading = false;
					if(err.response){
						return Promise.reject(err.response.data);          
					} else {
						return Promise.reject(ERR_MSG_CONEXION);
					}
				}
				
				
				GrlService.getCargarCatalogosBase(this.$store.state.auth.currentAppCtx).then(
					data => {
						this.$store.dispatch('auth/setCatalogos', data);			

						if(this.$store.state.auth.currentAppCtx.id_contexto_tipo == 2) { //Es Ctx Multidomus
							MdsService.getCatalogosBase(this.$store.state.auth.currentAppCtx).then(
								data => {
									this.$store.dispatch('auth/setMdsCatalogos', data);

									if(this.$store.state.auth.user.cambiar_passkey == 'S') {
										this.$router.push('/cambiarcontrasena');	
									} else {
										this.$router.push('/');
									}
								}
							).catch(
								err => {
									if(err.response){
										return Promise.reject(err.response.data);          
									} else {
										return Promise.reject(ERR_MSG_CONEXION);
									}        
								}
							)	
						} else {
							if(this.$store.state.auth.user.cambiar_passkey == 'S') {
								this.$router.push('/cambiarcontrasena');	
							} else {
								this.$router.push('/');
							}
						}
					}
				).catch(
					err => {
						this.loading = false;
						if(err.response){
							return Promise.reject(err.response.data);          
						} else {
							return Promise.reject(ERR_MSG_CONEXION);
						}        
					}
				);		
			}
		}
	}
</script>

<style scoped>
	.main {
		top: 100px;
		width: 500px;
		margin: 0px auto;
		padding: 50px auto;
		border: 1px solid;
	}

	.center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 10px;
	}

	.right {
	float: right;
	}	

	.mkt-bkg {
		background: url("/mkt-login-bg.jpg") no-repeat;	
		background-size: cover;
	}

	.mds-bkg{
		background: url("/mds-login-bg.jpg") no-repeat;
		background-size: cover;
	}	
</style>
