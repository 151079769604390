import AuthService from '../service/auth.service';

export function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));
  
  if(user)
  {
    let currentDate = new Date();
    let expireDate = Date.parse(user.expireby);
    
    if (currentDate > expireDate) { 
      AuthService.refreshToken(user).then(()=>{
        return { Authorization: `Bearer ${user.jwt}` }; // for Spring Boot back-end 
      })
    } 

    return { Authorization: `Bearer ${user.jwt}` }; // for Spring Boot back-end
  }
}

export function authHeaderMultiPart() {
  let user = JSON.parse(localStorage.getItem('user'));
  
  if(user)
  {
    let currentDate = new Date();
    let expireDate = Date.parse(user.expireby);
    
    if (currentDate > expireDate) { 
      AuthService.refreshToken(user).then(()=>{
        return { Authorization: `Bearer ${user.jwt}` }; // for Spring Boot back-end 
      })
    } 

   return { 
    "Authorization": `Bearer ${user.jwt}`,
    "Content-Type": 'multipart/form-data'
    }; // for Spring Boot back-end

  }
}


