import axios from 'axios';
import {authHeader, authHeaderMultiPart}  from './auth-header';
import {API_URL_MDS_CATALOGOS, API_URL_MDS_CONTADORES, API_URL_MDS_CXC, API_URL_MDS_RECLAMOS, API_URL_MDS_RESERVAS, ERR_MSG_CONEXION } from './constants';

class MdsService {

  getCatalogosBase(AppCtx)
  {    
    return axios.post(API_URL_MDS_CATALOGOS + '/catalogosbase',
    {
      AppCtx: `${AppCtx.codigo}`,
      Id: `${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(
      response => {
        return response.data;
      }
    )
    .catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getEntidades(AppCtx, IdEntidad)
  {
      return axios.post(API_URL_MDS_CATALOGOS + '/entidades', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.complejo.id_complejo}`,
        IdEntidad: `${IdEntidad}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  buscarEntidades(AppCtx, filtro)
  {
      return axios.post(API_URL_MDS_CATALOGOS + '/entidadesbuscar', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.complejo.id_complejo}`,
        Filtro: `${filtro}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  registrarEntidad(AppCtx, entidad)
  {
    let data = JSON.stringify(entidad);
    return axios.post(API_URL_MDS_CATALOGOS + '/registrarentidad', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${data}`},
      { headers: authHeader() }).then(response => {
      return response.data;
    }
      ).catch(
        err => {
          if(err.response){
            console.log(err.response);
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }        
        }
    );    
  }

  eliminarEntidad(AppCtx, entidad)
  {
    let id_entidad = entidad.id_entidad;
    
    return axios.post(API_URL_MDS_CATALOGOS + '/eliminarentidad', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_entidad}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    )           
  }

  getInmuebles(AppCtx, IdInmueble)
  {
      return axios.post(API_URL_MDS_CATALOGOS + '/inmuebles', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.complejo.id_complejo}`,
        IdInmueble: `${IdInmueble}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }  

  getInmueblesXEntidad(AppCtx, IdEntidad)
  {
      return axios.post(API_URL_MDS_CATALOGOS + '/inmueblesentidad', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.complejo.id_complejo}`,
        IdEntidad: `${IdEntidad}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }  

  getInmueblesEmailsNotificacion(AppCtx, IdCtaHabienteInfo)
  {
      return axios.post(API_URL_MDS_CATALOGOS + '/inmueblesemailsnotificacion', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${IdCtaHabienteInfo}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }  

  enviarEmailEstadoCuenta(AppCtx, Opciones, Emails)
  {    
    var data = JSON.stringify(Emails);
    
    return axios.post(API_URL_MDS_CXC + '/enviarnotificacionestadocuenta', {
        AppCtx: `${AppCtx.codigo}`,
        Filtro:`${JSON.stringify(Opciones)}`,
        Data: data
      }, 
      { 
        headers: authHeader()
      }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  enviarEmailResumenSaldos(AppCtx, Opciones, Emails)
  {    
    var data = JSON.stringify(Emails);
    
    return axios.post(API_URL_MDS_CXC + '/enviarnotificacionresumensaldo', {
        AppCtx: `${AppCtx.codigo}`,
        Filtro:`${JSON.stringify(Opciones)}`,
        Data: data
      }, 
      { 
        headers: authHeader()
      }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  buscarInmuebles(AppCtx, Filtro) {
      let data = JSON.stringify(Filtro);

      return axios.post(API_URL_MDS_CATALOGOS + '/inmueblesbuscar', {
        AppCtx: `${AppCtx.codigo}`,
        Data: `${data}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  registrarInmueble(AppCtx, inmueble)
  {
    let data = JSON.stringify(inmueble);
    return axios.post(API_URL_MDS_CATALOGOS + '/registrarinmueble', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${data}`},
      { headers: authHeader() }).then(response => {
      return response.data;
    }
      ).catch(
        err => {
          if(err.response){
            console.log(err.response);
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }        
        }
    );    
  }

  eliminarInmueble(AppCtx, inmueble)
  {
    let id_inmueble = inmueble.id_inmueble;
    
    return axios.post(API_URL_MDS_CATALOGOS + '/eliminarinmueble', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_inmueble}`},
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    )           
  }

  InmuebleVincularEntidad(AppCtx, Inmueble, id_entidad, tipo)
  {
    let id_inmueble = Inmueble.id_inmueble;

    return axios.post(API_URL_MDS_CATALOGOS + '/inmueblevincularentidad', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_inmueble}`,
        IdEntidad: `${id_entidad}`,        
        Tipo: tipo},              
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    )           
  }

  InmuebleDesvincularEntidad(AppCtx, Inmueble, tipo)
  {
    let id_inmueble = Inmueble.id_inmueble;

    return axios.post(API_URL_MDS_CATALOGOS + '/inmuebledesvincularentidad', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${id_inmueble}`,
        Tipo: tipo},             
      { headers: authHeader() }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    )           
  }

  async getLoginsDisponiblesInmueble(AppCtx, IdInmueble) {
      return axios.post(API_URL_MDS_CATALOGOS + '/inmuebleslogindisponibles', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${IdInmueble}` 
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getInmueblesResumenSaldos(AppCtx, filtro)
  {
      return axios.post(API_URL_MDS_CXC + '/getestadocuentaresumen', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.complejo.id_complejo}`,
        IdClasificacion: `${filtro.id_clasificacion}`,
        Nivel1: `${filtro.nivel1}`, 
        Nivel2: `${filtro.nivel2}`, 
        Nivel3: `${filtro.nivel3}`, 
        Nivel4: `${filtro.nivel4}`, 
        Nivel5: `${filtro.nivel5}`, 
        IdInmueble: `${filtro.IdInmueble}`, 
        IdMoneda: `${filtro.IdMoneda}`,
        Desde: filtro.desde,
        Hasta: filtro.hasta       
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getInmueblesEstadoCuenta(AppCtx, filtro)
  {
      return axios.post(API_URL_MDS_CXC + '/getestadocuenta', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.complejo.id_complejo}`,
        IdInmueble: `${filtro.IdInmueble}`,
        IdMoneda: `${filtro.IdMoneda}`,
        Desde: filtro.desde,
        Hasta: filtro.hasta       
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getInmueblesResumenSaldosXLogin(AppCtx, filtro)
  {
      return axios.post(API_URL_MDS_CXC + '/getestadocuentaresumenxlogin', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.complejo.id_complejo}`,
        IdMoneda: `${filtro.IdMoneda}`
      }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }  

  getInmueblesSaldoTotalXLogin(AppCtx, IdMoneda)
  {
      return axios.post(API_URL_MDS_CXC + '/getsaldototalxlogin', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.complejo.id_complejo}`,
        IdMoneda: `${IdMoneda}`
      }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }  

  getPlanesServicio(AppCtx, IdInmueble)
  {
      return axios.post(API_URL_MDS_CATALOGOS + '/planesservicio', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${AppCtx.complejo.id_complejo}`,
        IdInmueble: `${IdInmueble}`,        
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getContadorTarifas(AppCtx)
  {
      return axios.post(API_URL_MDS_CATALOGOS + '/contadortarifas', {
        AppCtx: `${AppCtx.codigo}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  async registrarTarifa(AppCtx, Tarifa) {
    
    let data = JSON.stringify(Tarifa);

    return axios.post(API_URL_MDS_CATALOGOS + '/registrartarifa', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  async tarifaEnUso(AppCtx, Tarifa) {
    
    let data = JSON.stringify(Tarifa);

    return axios.post(API_URL_MDS_CATALOGOS + '/contadortarifaenuso', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data.en_uso;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  eliminarContadorTarifa(AppCtx, Tarifa)
  {
    let data = JSON.stringify(Tarifa);

    return axios.post(API_URL_MDS_CATALOGOS + '/eliminarcontadortarifa', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data.en_uso;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  GetContadorInfo(AppCtx, Contador)
  {
    let data = JSON.stringify(Contador);

    return axios.post(API_URL_MDS_CONTADORES + '/getcontador', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  GetUltimaLectura(AppCtx, Contador)
  {
    let data = JSON.stringify(Contador);

    return axios.post(API_URL_MDS_CONTADORES + '/getultimalectura', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  registrarLectura(AppCtx, Lectura)
  {
    let data = JSON.stringify(Lectura);
    return axios.post(API_URL_MDS_CONTADORES + '/registrarlectura', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${data}`},
      { headers: authHeader() }).then(response => {
      return response.data;
    }
      ).catch(
        err => {
          if(err.response){
            console.log(err.response);
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }        
        }
    );    
  }

  getContadoresPerfilesImportacion(AppCtx)
  {
    return axios.post(API_URL_MDS_CONTADORES + '/perfilesimportacion', {
        AppCtx: `${AppCtx.codigo}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  cargarArchivoLecturas(AppCtx, complemento, Archivo)
  {
    let formData = new FormData();
    
    formData.append("appctx", AppCtx.codigo);
    formData.append("id_perfil", complemento.id_perfil);    
    formData.append("id_empleado", complemento.id_empleado);    
    formData.append("file", Archivo);

    let auth = authHeaderMultiPart();

    return axios.post(API_URL_MDS_CONTADORES + '/validararchivolecturas',
    formData, 
    { headers: auth }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }
 
  procesarLecturasBatch(AppCtx, idPerfil, Batch) {
    
    let data = JSON.stringify(Batch);

    return axios.post(API_URL_MDS_CONTADORES + '/procesarlecturas', {
        AppCtx: `${AppCtx.codigo}`,
        IdPerfil: idPerfil,
        Data:`${data}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );    
  }

  getHistorialConsumo(AppCtx, Data)
  {
    return axios.post(API_URL_MDS_CONTADORES + '/historialconsumo', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${JSON.stringify(Data)}`
    }, { headers: authHeader() }).then(response => {
    return response.data;
    }
    ).catch(
    err => {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }
  
  removerLectura(AppCtx, id)
  {
    return axios.post(API_URL_MDS_CONTADORES + '/removercontadorlectura', {
      AppCtx: `${AppCtx.codigo}`,
      Id : id
    }, { headers: authHeader() }).then(response => {
    return response.data;
    }
    ).catch(
    err => {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  getTotalConsumosPendientesEmision(AppCtx, Data)
  {
    return axios.post(API_URL_MDS_CONTADORES + '/hayconsumospendientesemision', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${JSON.stringify(Data)}`
    }, { headers: authHeader() }).then(response => {
    return response.data;
    }
    ).catch(
    err => {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }


  getConsumosPendientesEmision(AppCtx, Data)
  {
    return axios.post(API_URL_MDS_CONTADORES + '/consumospendientesemision', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${JSON.stringify(Data)}`
    }, { headers: authHeader() }).then(response => {
    return response.data;
    }
    ).catch(
    err => {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  emitirCargoXConsumoContador(AppCtx, Lectura) {
    
    return axios.post(API_URL_MDS_CONTADORES + '/emitircargoxconsumo', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${JSON.stringify(Lectura)}`
  }, { headers: authHeader() }).then(response => {
      return response.data;
    }
  ).catch(
    err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
  );
  }   

  registrarInmueblePlanServicio(AppCtx, IdInmueble, IdPlanServicio) {
      return axios.post(API_URL_MDS_CATALOGOS + '/inmuebleregistrarplanservicio', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${IdInmueble}`,
        IdPlanServicio: `${IdPlanServicio}`,        
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );    
  }

  removerPlanServicioInmueble(AppCtx, IdInmueble, IdPlanServicio) {
      return axios.post(API_URL_MDS_CATALOGOS + '/inmuebleeliminarplanservicio', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${IdInmueble}`,
        IdPlanServicio: `${IdPlanServicio}`,        
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );    
  }  

  LoginsXInmueble(AppCtx, Inmueble){
    return axios.post(API_URL_MDS_CATALOGOS + '/inmueblelogins', {
      AppCtx: `${AppCtx.codigo}`,
      Id: `${Inmueble.id_inmueble}`       
  }, { headers: authHeader() }).then(response => {
    return response.data;
    }
  ).catch(
    err => {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
  );    
}

asignarLoginInmueble(AppCtx, Inmueble, Login){
  return axios.post(API_URL_MDS_CATALOGOS + '/inmuebleasignarlogin', {
    AppCtx: `${AppCtx.codigo}`,
    Id: `${Inmueble.id_inmueble}`,
    Login: `${Login.login}`,        
}, { headers: authHeader() }).then(response => {
  return response.data;
  }
).catch(
  err => {
    if(err.response){
      return Promise.reject(err.response.data);          
    } else {
      return Promise.reject(ERR_MSG_CONEXION);
    }        
  }
);    
}

removerLoginInmueble(AppCtx, Inmueble, Login){
      return axios.post(API_URL_MDS_CATALOGOS + '/inmuebledesvincularlogin', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${Inmueble.id_inmueble}`,
        Login: `${Login.login}`,        
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );    
  }

  getInmuebleHistorial(AppCtx, IdInmueble)
  {
      return axios.post(API_URL_MDS_CATALOGOS + '/inmueblehistorial', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${IdInmueble}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getReclamos(AppCtx, Filtro) {
    let data = JSON.stringify(Filtro);

      return axios.post(API_URL_MDS_RECLAMOS + '/getreclamos', {
        AppCtx: `${AppCtx.codigo}`,
        Data: `${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );    
  }

  getReclamosXLogin(AppCtx, Filtro) {
    let data = JSON.stringify(Filtro);

      return axios.post(API_URL_MDS_RECLAMOS + '/getreclamosxlogin', {
        AppCtx: `${AppCtx.codigo}`,
        Data: `${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );    
  }

  registrarReclamo(AppCtx, Reclamo) {
    let data = JSON.stringify(Reclamo);

      return axios.post(API_URL_MDS_RECLAMOS + '/registrar', {
        AppCtx: `${AppCtx.codigo}`,
        Data: `${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );    
  }

  getReclamoHistorial(AppCtx, idReclamo) {
      return axios.post(API_URL_MDS_RECLAMOS + '/historial', {
        AppCtx: `${AppCtx.codigo}`,
        Id: idReclamo.toString(),
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );    
  }

  getReclamoSuscriptores(AppCtx, idReclamo) {
      return axios.post(API_URL_MDS_RECLAMOS + '/suscriptores', {
        AppCtx: `${AppCtx.codigo}`,
        Id: idReclamo.toString(),
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );    
  }

  removerSuscriptor(AppCtx, idReclamoSuscriptor) {

    return axios.post(API_URL_MDS_RECLAMOS + '/removersuscriptor', {
      AppCtx: `${AppCtx.codigo}`,
      Id: idReclamoSuscriptor,
  }, { headers: authHeader() }).then(response => {
    return response.data;
    }
  ).catch(
    err => {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
  );    
  }

  registrarSuscriptor(AppCtx, idReclamo, email) {

    let suscripcion = {
     id_reclamo: idReclamo,
     email: email 
    };
    let data = JSON.stringify(suscripcion);

    return axios.post(API_URL_MDS_RECLAMOS + '/registrarsuscriptor', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${data}`,
  }, { headers: authHeader() }).then(response => {
    return response.data;
    }
  ).catch(
    err => {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
  );    
  }  

  getIdentificadoresXNivel(AppCtx, nivel, parentVal) {
        return axios.post(API_URL_MDS_CATALOGOS + '/getidentificadoresxnivel', {
          AppCtx: `${AppCtx.codigo}`,
          Id: `${AppCtx.complejo.id_complejo}`,
          Nivel: nivel,
          ParentVal: `${parentVal}`
      }, { headers: authHeader() }).then(response => {
        return response.data;
        }
      ).catch(
        err => {
          if(err.response){
            console.log(err.response);
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }        
        }
      );    
    }  

    getContadores(AppCtx, Filtro) {
      let data = JSON.stringify(Filtro);

        return axios.post(API_URL_MDS_CONTADORES + '/getcontadores', {
          AppCtx: `${AppCtx.codigo}`,
          Data: `${data}`,
      }, { headers: authHeader() }).then(response => {
        return response.data;
        }
      ).catch(
        err => {
          if(err.response){
            console.log(err.response);
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }        
        }
      );    
    }  

    registrarContador(AppCtx, Contador)
    {
      let data = JSON.stringify(Contador);
      return axios.post(API_URL_MDS_CONTADORES + '/registrarcontador', {
        AppCtx: `${AppCtx.codigo}`,
        Data: `${data}`},
        { headers: authHeader() }).then(response => {
        return response.data;
      }
        ).catch(
          err => {
            if(err.response){
              console.log(err.response);
              return Promise.reject(err.response.data);          
            } else {
              return Promise.reject(ERR_MSG_CONEXION);
            }        
          }
      );    
    }

    contadorEnUso(AppCtx, Contador)
    {
      let id_contador = Contador.id_contador;
      
      return axios.post(API_URL_MDS_CONTADORES + '/contadorenuso', 
        {
          AppCtx: `${AppCtx.codigo}`,
          Id: id_contador},
        { headers: authHeader() }).then(response => {
          return response.data.en_uso;
        }
      ).catch(
        err => {
          if(err.response){
            console.log(err.response);
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }        
        }
      )           
    }

    eliminarContador(AppCtx, Contador)
    {
      let id_contador = Contador.id_contador;
      
      return axios.post(API_URL_MDS_CONTADORES + '/removercontador', 
        {
          AppCtx: `${AppCtx.codigo}`,
          Id: id_contador},
        { headers: authHeader() }).then(response => {
          return response.data;
        }
      ).catch(
        err => {
          if(err.response){
            console.log(err.response);
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }        
        }
      )           
    }

    getRecursosReservaTipo(AppCtx) {
        return axios.post(API_URL_MDS_RESERVAS + '/getrecursostipo', {
          AppCtx: `${AppCtx.codigo}`
      }, { headers: authHeader() }).then(response => {
        return response.data;
        }
      ).catch(
        err => {
          if(err.response){
            console.log(err.response);
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }        
        }
      );    
    }      
    
    getRecursosReservaTipoDispo(AppCtx, Id) {
      return axios.post(API_URL_MDS_RESERVAS + '/getrecursostipodispo', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${Id}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );    
  }      

  RegistrarRecurso(AppCtx, Recurso) {      
    var data = JSON.stringify(Recurso);
    return axios.post(API_URL_MDS_RESERVAS + '/registrarrecurso', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${data}`,
  }, { headers: authHeader() }).then(response => {
    return response.data;
    }
  ).catch(
    err => {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
  );    
}  

  RemoverRecurso(AppCtx, Recurso) {      
    var data = JSON.stringify(Recurso);
    return axios.post(API_URL_MDS_RESERVAS + '/removerrecurso', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${data}`,
  }, { headers: authHeader() }).then(response => {
    return response.data;
    }
  ).catch(
    err => {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
  );    
  }  

  recursoEnUso(AppCtx, Recurso)
  {
    var data = JSON.stringify(Recurso);
    
    return axios.post(API_URL_MDS_RESERVAS + '/recursoEnUso', 
      {
        AppCtx: `${AppCtx.codigo}`,
        Data: `${data}`},
      { headers: authHeader() }).then(response => {
        return response.data.en_uso;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    )           
  }  
    GetReservaEstados(AppCtx) {
        return axios.post(API_URL_MDS_RESERVAS + '/getreservaestados', {
          AppCtx: `${AppCtx.codigo}`
      }, { headers: authHeader() }).then(response => {
        return response.data;
        }
      ).catch(
        err => {
          if(err.response){
            console.log(err.response);
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }        
        }
      );    
    }          
    
    getRecursosReserva(AppCtx, IdComplejo, IdTipo) {
        return axios.post(API_URL_MDS_RESERVAS + '/getrecursosxcomplejo', {
          AppCtx: `${AppCtx.codigo}`,
          IdComplejo: `${IdComplejo}`,
          IdTipo: `${IdTipo}`
      }, { headers: authHeader() }).then(response => {
        return response.data;
        }
      ).catch(
        err => {
          if(err.response){
            console.log(err.response);
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }        
        }
      );    
    }  
    
    getReservasXRecurso(AppCtx, Recurso) {
        var filtro =  {
          "id": `${Recurso.id_recurso}`,
          "id_empresa": Recurso.id_complejo 
        }
        
        var filtroData = JSON.stringify(filtro);
        return axios.post(API_URL_MDS_RESERVAS + '/getreservasxrecurso', {
          AppCtx: `${AppCtx.codigo}`,
          Data: `${filtroData}`,
      }, { headers: authHeader() }).then(response => {
        return response.data;
        }
      ).catch(
        err => {
          if(err.response){
            console.log(err.response);
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }        
        }
      );    
    }  

    RegistrarRecursoReserva(AppCtx, Reserva) {      
      var data = JSON.stringify(Reserva);
      return axios.post(API_URL_MDS_RESERVAS + '/registrarreserva', {
        AppCtx: `${AppCtx.codigo}`,
        Data: `${data}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );    
  }  

    CancelarRecursoReserva(AppCtx, Reserva) {      
      return axios.post(API_URL_MDS_RESERVAS + '/cancelarreserva', {
        AppCtx: `${AppCtx.codigo}`,
        Id: `${Reserva.id_recurso_reserva}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );    
  }  

  GenerarCargoXReserva(AppCtx, Reserva) {      
    var data = JSON.stringify(Reserva);
    return axios.post(API_URL_MDS_RESERVAS + '/generarcargoporreserva', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${data}`
  }, { headers: authHeader() }).then(response => {
    return response.data;
    }
  ).catch(
    err => {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
  );    
}  

enviarEmailReserva(AppCtx, Notif)
{    
  return axios.post(API_URL_MDS_RESERVAS + '/enviarnotificacionesreserva', {
      AppCtx: `${AppCtx.codigo}`,
      Id:`${Notif.id}`,
      Data: `${JSON.stringify(Notif.emails)}`
    }, 
    { 
      headers: authHeader()
    }).then(response => {
      return response.data;
    }
  ).catch(
    err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
  );
}  

    GetTrxXContrato(AppCtx, Condiciones) {
      return axios.post(API_URL_MDS_CXC + '/gettrxxcontrato', {
        AppCtx: `${AppCtx.codigo}`,
        Data: `${JSON.stringify(Condiciones)}`
    }, {headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }   

  emitirTrxXID(AppCtx, Cargo) {
    
    return axios.post(API_URL_MDS_CXC + '/getemitirtrxxid', {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${JSON.stringify(Cargo)}`
  }, { headers: authHeader() }).then(response => {
    response.data.id_movimiento_old = Cargo.id_movimiento;
    return response.data;
    }
  ).catch(
    err => {
      if(err.response){
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
  );
  }   
}

export default  new MdsService();
