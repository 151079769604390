<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<form @submit.prevent="onSubmit">
	<div :class="backgroundStyle">
		<!-- <div class="exception-panel">
			<div class="exception-content">
				<img src="assets/layout/images/pages/icon-404.svg" alt="roma"/>
				<h1>Afiliaciones</h1>
				<Button label="Regresar al inicio de sesión" icon="pi pi-arrow-left" @click="logout" />
			</div>			
		</div> -->
		<div class="center">
			<Card v-if="!registrado">
				<template #title>
					<h3 class="p-text-bold" style="text-align: center">Bienvenido a {{appName}}</h3>
					<h5>Por favor, llena los siguiente datos</h5>
				</template>
				<template #content>
					<div class="p-fluid">
						<div class="p-field">
							<div class="p-inputgroup">
								<span class="p-inputgroup-addon"><i class="pi pi-id-card"></i></span>
								<InputText v-model="v$.form.nombres.$model" placeholder="Nombres" :class="{'p-invalid': v$.form.nombres.$error}"/>
							</div>	
							<div v-for="(error, index) of v$.form.nombres.$errors" :key="index">
								<div class="p-error">{{ error.$message }}</div>
							</div>		
						</div>
						<div class="p-field">
							<div class="p-inputgroup">
							<span class="p-inputgroup-addon"><i class="pi pi-id-card"></i></span>
								<InputText v-model="v$.form.apellidos.$model" placeholder="Apellidos" :class="{'p-invalid': v$.form.apellidos.$error}"/>
							</div>							
							<div v-for="(error, index) of v$.form.apellidos.$errors" :key="index">
								<div class="p-error">{{ error.$message }}</div>
							</div>							
						</div>
						<div class="p-field">
							<div class="p-inputgroup">
								<span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
								<InputText v-model="v$.form.email.$model" placeholder="email" :class="{'p-invalid': v$.form.email.$error}"/>
							</div>
							<div class="p-error" v-if="v$.form.email.email.$invalid">La dirección de email es inválida</div>
						</div>							
						<div class="p-field">
							<div class="p-inputgroup">
							<span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
								<InputText v-model="v$.form.login.$model"  placeholder="Usuario" :class="{'p-invalid': v$.form.login.$error}"/>
							</div>
							<div v-for="(error, index) of v$.form.login.$errors" :key="index">
								<div class="p-error">{{ error.$message }}</div>
							</div>														
						</div>					
						<div class="p-field">
							<div class="p-inputgroup">
							<span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
								<InputText type="password" v-model="v$.form.passKey.$model" placeholder="Contraseña" :class="{'p-invalid': v$.form.passKey.$error}"/>				
								<!-- <Password v-model="v$.form.passKey.$model" placeholder="Contraseña" :class="{'p-invalid': v$.form.passKey.$error}" toggleMask/> -->
							</div>
							<div v-if="v$.form.passKey.required.$error">
								<div class="p-error">Debe proporcionar una constraseña</div>	
							</div>											
							<div v-if="v$.form.passKey.min.$error">
								<label class="p-error">Debe proporcionar una constraseña</label>	
							</div>											

							<!-- <div v-for="(error, index) of v$.form.passKey.$errors" :key="index">
								<div class="p-error">{{ error.$message }}</div>
							</div>								 -->
						</div>		
						<div class="p-field">
							<div class="p-inputgroup">
							<span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
								<InputText type="password" v-model="v$.form.confirmacion.$model" placeholder="Confirmar contraseña" :class="{'p-invalid': v$.form.confirmacion.$error}"/>
							</div>
							<div v-if="v$.form.confirmacion.sameAsPasskey.$error">
								<div class="p-error">Las contraseña y la confirmación deben ser iguales</div>	
							</div>														
						</div>								
						<Button class="p-fluid" :disabled="(v$.form.$errors.length > 0)" label="Registrar" icon="pi pi-check" @click="registrar" />
						<Message v-for="msg of messages" :severity="msg.severity" :key="msg.content">{{msg.content}}</Message>
					</div>					
				</template>				
			</Card>
			<Card v-if="registrado">
				<template #header>
					<center>
					<img src="/complete.jpg" style="height: 15rem; width: 15rem" />
					</center>
				</template>
				<template #title>
					<center>
					¡Cuenta registrada con éxito!
					</center>
				</template>
				<template #content>
					<center>
					<p>¡Tu cuenta ha sido creada con éxito!
En los próximos minutos recibirás un correo electrónico para activar tu cuenta y podras empezar a utilizar {{appName}}.
</p></center>
				</template>
				<template #footer>
					<center>
					<Button icon="pi pi-check" label="Continuar" @click="continuar"/>
					</center>
				</template>	
			</Card>			
		</div>
	</div>
	</form>
</template>

<script>
	import Loading from 'vue-loading-overlay';
	// Import stylesheet
	import 'vue-loading-overlay/dist/vue-loading.css';
	import GrlService from '../service/grl.service';
	import MdsService from '../service/mds.service';
	import useVuelidate from  '@vuelidate/core';
	import { email, required, minLength, sameAs } from '@vuelidate/validators';	
	import { ERR_MSG_CONEXION, APPNAME, BACKGROUND_STYLE } from '../service/constants';
	import AuthService from '../service/auth.service';

	export function validName(name) {
		let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
		
		if (validNamePattern.test(name)){
			return true;
		}
		
		return false;
	}

	export default {
		setup() { 
			return { v$: useVuelidate() };
		},
		components: {
			Loading
		},
		validations () {
			return {

				form: {
					nombres: {required, name_validation: {
						$validator: validName,
						$message: 'Nombre inválido. Los nombres válidos solo pueden contener letras, espacios y guiones (-)'
						}
					},
					apellidos : {required, name_validation: {
						$validator: validName,
						$message: 'Apellidos inválido. Los apellidos válidos solo pueden contener letras, espacios y guiones (-)'
						}
					},
					email: {required, email},
					login: {required, min: minLength(6)},
					passKey: {required, min: minLength(8)},
					confirmacion: {
						sameAsPasskey: sameAs(this.form.passKey),
					}		
				}
			}			
		},			
		data() {
			return {
				loading: false,
				registrado: false,
				appName: APPNAME,	
				messages: [],
				backgroundStyle: `exception-body notfound ${BACKGROUND_STYLE}`,
				form: {
					nombres: '',
					apellidos: '',
					email: '',
					login: '',
					passKey: '',
					confirmacion: ''
				}								
			}
		},
		mounted() {
			this.v$.$reset();
		},		
		methods: {
			registrar(){
				let login = {
					login: this.form.login,
					passKey: this.form.passKey,
					nombres: this.form.nombres,
					apellidos: this.form.apellidos,
					email: this.form.email
				};
				
				this.loading = true;
				this.messages = [];				
				AuthService.registro(login).then((response)=> {
					console.log(response);
					this.loading = false;
					this.registrado = true;
				}).catch((error)=>{
					this.loading = false;
					this.messages.push(
					{severity: 'error', content: `${error.error}`}						
					);
				});
			},

			continuar() {
				this.$router.push('/');
			},

			goCtx(ctx)
			{
				this.$store.dispatch('auth/setAppCtx', ctx);
				
				GrlService.getCargarCatalogosBase(this.$store.state.auth.currentAppCtx).then(
					data => {
						this.$store.dispatch('auth/setCatalogos', data);			

						if(this.$store.state.auth.currentAppCtx.id_contexto_tipo == 2) { //Es Ctx Multidomus
							MdsService.getCatalogosBase(this.$store.state.auth.currentAppCtx).then(
								data => {
									this.$store.dispatch('auth/setMdsCatalogos', data);
									this.$router.push('/');
								}
							).catch(
								err => {
									if(err.response){
										return Promise.reject(err.response.data);          
									} else {
										return Promise.reject(ERR_MSG_CONEXION);
									}        
								}
							)	
						} else {
							this.$router.push('/');							
						}
					}
				).catch(
					err => {
						if(err.response){
							return Promise.reject(err.response.data);          
						} else {
							return Promise.reject(ERR_MSG_CONEXION);
						}        
					}
				);		
			}
		}
	}
</script>

<style scoped>
	.main {
		top: 100px;
		width: 500px;
		margin: 0px auto;
		padding: 50px auto;
		border: 1px solid;
	}

	.center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 10px;
	}

	.right {
	float: right;
	}	

	.mkt-bkg {
		background: url("/mkt-login-bg.jpg") no-repeat;	
		background-size: cover;
	}

	.mds-bkg{
		background: url("/mds-login-bg.jpg") no-repeat;
		background-size: cover;
	}	

	::v-deep(.p-password input) {
		width: 15rem
	}
</style>
